$( document ).ready(function() {
     console.log( "ready!" );
    $('[data-toggle="popover"]').popover();
    $('[data-toggle="tooltip"]').tooltip();
    $('.carousel').carousel();
    //$(".sticker").sticky({topSpacing:0});
    //AOS.init();
    //var Sticky = require('sticky-js');
    var sticky = new Sticky('header');
    $(".arrow_scroll").click(function(e) {
	    e.preventDefault();
	    $('html, body').animate({
	        scrollTop: $("#start").offset().top - $('header').height()
	    }, 500);
	});
});
